// for npm roboto-fontface package (to load local files)
$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~roboto-fontface/css/roboto/sass/roboto-fontface";

// for npm material-icons package (to load local files)
$material-icons-font-path: "~material-icons/iconfont/";
@import "~material-icons/iconfont/material-icons.scss";

@import url("https://cdn.quilljs.com/1.2.2/quill.snow.css");
@import url("https://cdn.quilljs.com/1.2.2/quill.bubble.css");

// indigo-pink, deeppurple-amber, purple-green and pink-bluegrey.
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/leaflet/dist/leaflet.css";

@import "app/theme/styles/base";
@import "app/theme/styles/theme";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/rtl";


// ag-grid styles
@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-balham.css";

//font -awsome

@import "../node_modules/font-awesome/scss/font-awesome.scss";

//Common Form-control css

.material-icons,
.material-icons-two-tone,
.material-icons-sharp,
.material-icons-round,
.material-icons-outlined {
  // font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}
.pointer-event-none
{
    pointer-events: none !important;
}

.header-compress
{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ag-theme-balham .ag-ltr .ag-header-select-all {
  margin-right: 12px;
  margin-left: 4px !important;
}


.ag-theme-balham .ag-header-cell,
.ag-theme-balham .ag-header-group-cell,
.ag-theme-balham .ag-ltr .ag-cell {
    border-right: 1px solid #D9DDDF;
}
.mat-checkbox-inner-container {
  display: inline-block;
  height: 12px !important;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 12px !important;
  flex-shrink: 0;
}

.form-control {
  color: #707070 !important;
  background-color: white !important;
  border: 1px solid #a1a1a1 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-radius: 5px !important;
}

.form-input-control {
  color: #fff !important;
  background: #3f51aa !important;
  border: 1px solid #a1a1a1 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  border-radius: 5px !important;
}


// Sorting Icon Position

.filter-icon
{
  position: absolute;
  right: 3px;
  top: 21px;
}

.position-right-withInput
{
  position: absolute !important;
  right: 0% !important;
  top: -4px !important;
}

.position-right-withOutInput
{
  position: absolute !important;
  right: 5% !important;
  top: -3px !important;
}
.mx-2
{
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mx-udf-select
{
  margin-left: 500px !important;
  margin-right: 10px !important;
}
// Sorting Icon Position

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.myPanelClass {
  margin-top: 33px!important;
}
.myPanelClass1 {
  margin-top: 70px!important; // For investment
}

.b-r-5 {
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-default {
  cursor: default !important;
}

.align-self-center {
  align-self: center;
}

.align-middle {
  vertical-align: middle;
}

.mat-form-select {
  color: rgb(0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #a1a1a1 !important;
}

.mat-form-element {
  color: rgb(0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #a1a1a1 !important;
  font-size: 11px;
  padding: 0 4px;
  font-weight: 400;
}

.mat-icon-button {
  padding: 0 !important;
  min-width: 0 !important;
  width: 22px !important;
  height: 22px !important;
  flex-shrink: 0 !important;
  line-height: 22px !important;
  border-radius: 50% !important;
}

mat-calendar{
  .mat-icon-button{
    width:40px !important;
    height: 40px !important;
  }
}

.mat-select-value-text {
  font-size: 11px;
  font-weight: 400;
  padding-left: 8px;
  display: inline-flex;
  vertical-align: middle;
}

// li:hover {
//   background-color: #b3c4c7 !important;
// }

.toggling-button {
  position: absolute !important;
  right: 0 !important;
  top: 0;
  z-index: 10000;
  background-color: #1a237e !important;
  height: 48px !important;
  color: #fff !important;
  border-radius: 0 !important;
}

.table-collapse-row {
  min-height: 30px !important;
  max-height: 30px !important;
  height: 30px !important;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
}

.mat-icon-button {
  padding: 0;
  min-width: 0;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}

.position-center {
  position: absolute;
  left: 50%;
}

.position-right {
  position: absolute;
  right: 1%;
}


.pull-right {
  float: right !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.side-nav-button {
  border-radius: 10px;
  border-color: #ffffff;
  background-color: #ffffff;
  max-width: 50px;
  max-height: 50px;
  margin-left: 10px !important;
  margin-bottom: 15px !important;
}

//Common Form-control css

// Dashboard //

.dashbord-containt {
  flex-direction: row !important;
  box-sizing: border-box !important;
  display: flex !important;
  place-content: center space-evenly;
  align-items: center;
  padding-top: 130px;
  margin: 0 max(20%, 10px);
}

.dashbord-icon {
  flex-direction: row !important;
  box-sizing: border-box !important;
  display: flex !important;
  place-content: center space-around !important;
  align-items: center !important;
  padding-top: 80px !important;
}

.dashbord-text {
  flex-direction: row !important;
  box-sizing: border-box !important;
  display: flex !important;
  place-content: center space-around !important;
  align-items: center !important;
}

// Dashboard //

// Display //

.d-webkit-inline-box {
  display: -webkit-inline-box !important;
}

// Display //

//height//

.max-height-25px {
  max-height: 25px;
}

.min-height-25px {
  min-height: 25px;
}
.height-25px {
  height: 25px;
}

.h-35px {
  height: 35px;
}

.h-22px {
  height: 22px;
}
.h-20px{
  height: 20px;
}

//height//

//color//
.redBackground {
  background-color: red !important;
}

.red {
  color: red !important;
}

.rgba-theam-blue {
  color: rgba(28, 28, 149, 0.893);
}

.bg-toolbar {
  background-color: #1a237e !important;
}

.green {
  color: green;
}

.yellow {
  color: yellow;
}

.theame-blue {
  color: #1a237e;
}

.theame-white {
  color: white;
}

.Background-blue {
  background-color: #ffffff;
}


//color//

// line-height //

.line-height-1-52rem {
  line-height: 1.52rem;
}

.line-height-1-75rem {
  line-height: 1.75rem;
}

// line-height //

// Width //

.w-240 {
  width: 240px !important;
}

.w-145 {
  width: 145px;
}

.w-150px {
  width: 150px !important;
}

.w-15px {
  width: 15px !important;
}

.w-183px {
  width: 183px !important;
}
.w-70 {
  width: 70% !important;
}

.w-100px {
  width: 100px !important;
}
.w-20-per {
  width: 20%;
}

.w-30-per {
  width: 30% !important;
}

.w-45-per {
  width: 45% !important;
}

.w-55-per {
  width: 55%;
}

.w-90-per {
  width: 90% !important;
}

.w-80-per {
  width: 80% !important;
}

.w-240 {
  width: 240px;
}
.w-340 {
  width: 340px;
}

.width-fullspace
{
  width: -webkit-fill-available !important;
}

.w-600 {
  width: 600px !important;
}


.w-500 {
  width: 500px !important;
}

.w-400 {
  width: 400px !important;
}

.w-300 {
  width: 283% !important;
}
// Width //

// font //

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px;
}

.f-20 {
  font-size: 20px;
}

.f-40 {
  font-size: 40px;
}

.f-35 {
  font-size: 35px;
}

.f-30 {
  font-size: 30px;
}

.f-18 {
  font-size: 18px;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-800 {
  font-weight: 800;
}

// font //

// padding //

.py-4 {
  padding-bottom: 4px;
  padding-top: 4px;
}

.py-6 {
  padding-bottom: 6px;
  padding-top: 6px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pl-35px {
  padding-left: 35px;
}

.pl-30px {
  padding-left: 30px;
}

.p-t-10 {
  padding-top: 10px !important;
}

.pb-0 {
  padding-bottom: 0;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}


.b-0 {
  border: 0 !important; }

.p-t-2px {
  padding-top: 2px !important;
}

.p-b-10px {
  padding-bottom: 10px !important;
}

.p-b-2px {
  padding-bottom: 2px !important;
}

.p-t-1px {
  padding-top: 1px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-l-16 {
  padding-left: 16px;
}

.pr-8px {
  padding-right: 8px;
}

.pr-5px {
  padding-right: 5px !important;
}


.pl-12px {
  padding-left: 12px;
}

.p-14 {
  padding: 14px !important;
}

// padding //

//margin//

.mb-0{
  margin-bottom: 0 !important;
}

.m-l-10px {
  margin-left: 10px;
}

.my-1 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.m-t-3 {
  margin-top: 3px;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-r-20 {
  margin-right: 20px;
}

//margin//

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.app.horizontal-menu .inner-sidenav-content {
  padding-top: 0px !important;
  min-height: calc(100% - (56px + 56px + 8px * 2));
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 1px;
  display: none !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0rem !important;
}

//ngx datatable css

.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: transparent;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}

.datatable-checkbox input[type="checkbox"] {
  position: relative;
  margin: 8px 0;
  cursor: pointer;
  outline: none;
  border-color: black !important ;
}

.ngx-datatable.material.cell-selection 
.datatable-body-cell:hover {
  background-color: #a0c1f7;
}

.ngx-datatable.material
{
  .datatable-row-left 
     {
      .datatable-header-cell{
        height: 48px;
        mat-checkbox{
          position: relative;;
          top:15px;
        }
      }
    }
  .datatable-body{
    .datatable-body-row.checkedRow{
      .datatable-row-left 
     {
      background-color: #eef98a !important;
      }
    }
    .datatable-body-row.deletedRow{
      .datatable-row-left 
     {
      background-color: #f98a9c !important;
      }
    }
    .datatable-body-row.lockedRow{
      .datatable-row-left 
     {
      background-color: rgb(242, 238, 251) !important;
      }
    }

    .datatable-body-row.finalRow{
      .datatable-row-left 
     {
      background-color: rgb(128,0,128) !important;
      }
    }
    .datatable-body-row.pasteRowColor,.datatable-body-row.AddedRowColor{
      .datatable-row-left 
     {
      background-color: #8ab8f9 !important;
      }
    }
    .datatable-body-row.datatable-row-odd{
      .datatable-row-left 
     {
      background-color: #eee;
      background-position: 100% 0px;
      background-repeat: repeat-y;
      background-image: none; 
      }
    }
    .datatable-body-row.datatable-row-even{
      .datatable-row-left 
     {
      background-color: #fff;
      background-position: 100% 0px;
      background-repeat: repeat-y;
      background-image: none; 
      }
    }
    .datatable-body-row{
      .active{
        background-color: #0829e0 !important;
        input
        {
          color: #fff !important;
        }
        span {
          color: #fff !important;
        }
        div
        {
          color: #fff !important;
        }
      }
    }
  }
    
  
}

// .ngx-datatable.material
//   .datatable-body
//   .datatable-body-row
//   .datatable-body-cell {
//   padding: 0 1.2rem;
// }

.datatable-checkbox input[type="checkbox"]:before {
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: none;
}

.ngx-datatable.material .datatable-header .datatable-header-cell,
.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  display: flex;
  justify-content: center;
  padding: 0 !important;
  text-align: center;
  align-items: center;
  font-size: 12px;
  .datatable-body-cell-label
  {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span:only-child{
      padding: 0 10px;
    }
    .div-class{
      padding: 0 10px;
      display: initial;
      align-items: flex-end;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.input-as-div{
  border: none;
  outline: none;
  font-size: 12px;
  background-color: inherit;
  width: 100% !important;
  padding: 0 10px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  color: #1a237e;
  font-weight: bold !important;
  padding: 3px 0 !important;
  font-size: 11px;
}

.ngx-datatable .datatable-header-cell,
.datatable-body-cell {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.fixed-row .datatable-scroll,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.material.checkbox-selection .datatable-body-row.active {
  background-color: #f1ffb4;
  color: #fff;
}

.ngx-datatable.material
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: #a0c1f7;
}

.datatable-checkbox input[type="checkbox"]:before,
.datatable-checkbox input[type="checkbox"]:after {
  width: 0.8rem;
  height: 0.8rem;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: -6px !important;
  top: -10px !important;
  z-index: 1;
  //border: 1px solid #49494900;
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  line-height: 1.5rem !important;
  height: 1.5rem !important;
  font-size: 12px !important;
}

.mat-option {
  height: 20px !important;
  font-size: 12px !important;
}

.ngx-datatable.material .datatable-body .progress-linear {
  display: none;
}

.mat-menu-panel {
  border-radius: 0 !important;
  position: relative;
  right: 10px;
  min-height: max-content !important;
  max-height: 195px !important;
  min-width: 150px !important;
}


 // this overriding the checkbox and stop it from displaying...
// input[type="checkbox"i] {
//   background-color: transparent !important;
//   cursor: pointer;
//   appearance: initial !important;
//   box-sizing: border-box;
//   margin: auto !important;
//   padding: auto !important;
//   border: initial !important;
// }

.datatable-checkbox input[type="checkbox"]:after {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: transparent;
  cursor: pointer;
}

.ngx-datatable.material {
  .datatable-header {
    min-width: 100%;

    .datatable-header-cell {
      background-color: #a0c1f7;
    }
  }

  .datatable-body {
    position: relative;
    //max-height: calc(100vh - 350px);
    //min-height: calc(100vh - 350px);
    min-width: 100%;
    overflow-y: scroll;
  }
}

.app.indigo-light .mat-toolbar.mat-primary {
  background: #283593;
  color: white;
}

.column-filter-applied {
  color: #3da2da;
}

// Pagination //
.ngx-datatable .datatable-footer {
  input {
    width: 50px;
    margin-left: 20px;
    height: fit-content;
    padding: 4px 6px;
  }

  button {
    margin-left: 13px;
    padding: 4px 7px;
  }

  select {
    margin-left: 7px;
    padding: 3px 7px;
  }

  label {
    white-space: nowrap;
    margin-left: 5px;
  }

  .position-right {
    position: absolute;
    right: 25px;
  }

  .position-right-0 {
    position: absolute !important;
    right: 0px !important;
  }
}

// Pagination //

// for cross button in mat select in LookUp value Page
.mat-select-search-inside-mat-option .mat-select-search-clear {
  top: -4px !important;
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
  position: sticky !important;
}

.mat-option[aria-disabled="true"].contains-mat-select-search .mat-icon {
  font-size: 18px !important;
}

// reset-filter pop up

.mat-dialog-container {
  display: block;
  padding: 0 !important;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.reset-filter .ngx-datatable.material {
    .datatable-body {
      position: relative;
      max-height: calc(100vh - 500px);
      min-height: calc(100vh - 500px);
      overflow-y: auto;
      overflow-x: hidden;
      .datatable-body-cell {
        display: flex;
        justify-content: left !important;
        padding: 0.2rem 1.2rem;
        text-align: center;
        align-items: center;
        font-size: 12px;
      }
    }
}

.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 12px;
}
// reset-filter pop up

// for tabs in funds
.mat-tab-label {
  height: 25px !important;
  padding: 0 20px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: max-content !important;
}

.deletedRow {
  color: #f98a9c !important;

  &.ag-row-selected::before{
   color: #f98a9c !important;
  }
  cursor: not-allowed;
//pointer-events: none !important;
}


.disabledRowColor {
    color: rgb(188, 188, 188) !important;
  &.ag-row-selected::before{
    color: rgb(188, 188, 188) !important;
  }
}

.copiedRow {
  background-color: #84abcd !important;

  &.ag-row-selected::before{
    background-color: #84abcd !important;
  }
}

.lockedRow {
  color: rgb(11, 141, 184)   !important;
    &.ag-row-selected::before{
    color: rgb(11, 141, 184) !important;
  }
 
}



.positiveCell {
  color: blue !important;
  float: right;
  text-align: right;
}
.negativeCell {
  color: red !important;
  float: right;
  text-align: right;
}

.defaultCurrencyCell {
  float: right;
  text-align: right;
}

.draftRow {
  color: peru !important;
    &.ag-row-selected::before{
    color: peru !important;
  }
 
}
.finalRow {
  color: #800080 !important;
    &.ag-row-selected::before{
    color: #800080 !important;
  }
 font-weight : bold;
}

.finalRow .positiveCell {
  color: #800080 !important;
  float: right;
  text-align: right;
}

.finalRow .negativeCell {
  color: #800080 !important;
  float: right;
  text-align: right;
}


.sumRow {
  //color: white !important;
  //   &.ag-row-selected::before{
  //   color: white !important;
  // }
  background-color: #a0c1f7 !important;
  pointer-events: none;
  font-weight: bold;
 
}



.checkedRow {
  background-color: #eef98a !important;
  &.ag-row-selected::before{
    background-color: #eef98a !important;
  }
}
.pasteRowColor {
  background-color: #8ab8f9 !important;
  &.ag-row-selected::before{
    background-color: #8ab8f9 !important;
  }
}
.AddedRowColor {
  background-color: #8ab8f9 !important;
  &.ag-row-selected::before{
    background-color: #8ab8f9 !important;
  }
}

// for butto side context hide

.toggling-button-cell {
  max-width: 34px !important;
  min-width: 34px !important;
  background-color: white !important;
  position: absolute !important;
  margin-left: 5px !important;
}

.app.indigo-light
  .mat-checkbox-indeterminate.mat-accent
  .mat-checkbox-background,
.app.indigo-light .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1a237e;
}

.reset-filter .ngx-datatable.material .datatable-body {
  position: relative;
  max-height: calc(100vh - 550px);
  min-height: calc(100vh - 550px);
  overflow-y: auto;
  overflow-x: hidden;
}

.text-align-left {
  justify-content: left !important;
}

.text-align-right {
  justify-content: right !important;
}

.mat-drawer-content {
  position: relative;
  z-index: 1;
  display: block;
  height: 101% !important;
  overflow: auto;
}

.mat-datepicker-content {
  .mat-calendar-header {
    .mat-calendar-controls {
      .mat-icon-button {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}

.mat-form-element-red {
  color: black;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid red !important;
}

//sweet alart
.swal2-styled {
  margin: 0.3125em;
  padding: 5px 11px;
  font-size: 13px !important;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px transparent;
  font-weight: 500;
}

.p-14 {
  padding: 14px !important;
}

//sweet alart

.required-asterisk {
  color: red;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.2em;
}

// content-wrapper
.content-wrapper {
  height: calc(100vh - 56px);
  display: flex;

  .top-toolbar {
    flex: 0 0 56px;
  }

  .input-wrapper {
    display: block;
    flex: 0 0 auto;

    &.hide {
      height: 0;
      display: none;
    }
  }

  .sub-toolbar {
    flex: 1 0 auto;
  }

  .table-wrapper {
    flex: 1 1 auto;

    mat-tab-group {
      height: 100% !important;

      .mat-tab-body-wrapper {
        height: 100% !important;

        div[role="table"] {
          height: 100% !important;
          display: flex;
          flex-direction: column;

          datatable-header {
            flex: 0 0 48px;
            justify-self: flex-start;
          }
          datatable-body {
            flex: 1 1 calc(100% - 94px);
          }
          datatable-footer {
            flex: 0 1 46px;
            justify-self: flex-end;
          }
        }
      }
    }

    .ngx-datatable {
      height: 100% !important;
    }
  }

  .Lookuptable-wrapper{
    flex: 1 1 auto;
        div[role="table"]{
          height: 100% !important;
          display: flex;
          flex-direction: column;

          datatable-header{
            flex: 0 0 48px;
            justify-self: flex-start;
          }
          .datatable-body {
            position: relative;
            min-height: calc(100vh - 205px);
            min-width: 100%;
            overflow-y: scroll;
          }
          datatable-footer{
            flex: 0 1 46px;
            justify-self: flex-end;
          }
        }

    .ngx-datatable{
      height: 100% !important;
    }
  }

  .transaction-wrapper{
    flex: 1 1 auto;

    mat-tab-group {
      height: 100% !important;

      .mat-tab-body-wrapper {
        height: 100% !important;

        div[role="table"]{
          height: 100% !important;
          display: flex;
          flex-direction: column;

          datatable-header{
            flex: 0 0 48px;
            justify-self: flex-start;
          }
          datatable-body {
            flex: 1 1 calc(100% - 94px);
          }
          datatable-footer{
            flex: 0 1 46px;
            justify-self: flex-end;
          }
        }

    .ngx-datatable{
      height: 100% !important;
    }
  }
  }
  }

  .level-wrapper
  {
    flex: 1 1 auto;

    mat-tab-group {
      height: 100% !important;

      .mat-tab-body-wrapper {
        height: 100% !important;

        .select-div
        {
          flex: 0 0 auto;
        }
        div[role="table"]{
          height: 100% !important;
          display: flex;
          flex-direction: column;

          datatable-header{
            flex: 0 0 48px;
            justify-self: flex-start;
          }
          datatable-body {
            min-height: 400px;
            max-height: 400px;
          }
          datatable-footer{
            flex: 0 1 46px;
            justify-self: flex-end;
          }
        }

      }
    }
  }
}

.user-wrapper
{
  .select-div
  {
    flex: 0 0 auto;
  }
  div[role="table"]{
    height: 100% !important;
    display: flex;
    flex-direction: column;

    datatable-header{
      flex: 0 0 48px;
      justify-self: flex-start;
    }
    datatable-body {
      min-height: 462px;
      max-height: 462px;
    }
    datatable-footer{
      flex: 0 1 46px;
      justify-self: flex-end;
    }
  }

}

.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 2px 5px -3px rgb(0 0 0 / 20%) !important;
}
.h-100 {
  height: 100% !important;
}
// dhanshree  ----use style for input
.Background-gray {
  background-color: #eee;
}
// end


// Swal Alart

.swal2-icon .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 2.75em !important;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 3em !important;
  height: 3em !important;
  margin: 1.5em auto 0.6em !important;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 13.0625px;
  top: 22px !important;
  transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 8px !important;
  transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 10px !important;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 22px !important;
  width: 1.9375em !important;
  height: 0.3125em !important;
  border-radius: 0.125em;
  background-color: #f27474;
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 23px !important;
  right: 5px !important;
  width: 28px !important;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 28px !important;
  left: 7px !important;
  width: 17px !important;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-38deg) !important;
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}

// Swal Alart

// for grid tile
.mat-grid-list
{
  .mat-grid-tile
  {
    font-size: 11px;
    font-weight: bold;
    .mat-grid-tile-content {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: left !important;
      height: 100%;
      padding: 0;
      margin: 0;
      mat-select{
        line-height: 22px;
        font-size: 11px;
      }
      textarea{
        font-size:  11px;
        height: -webkit-fill-available !important;;
      }
    }
  }
  .new-InputWidth{
    left: 70px !important;
    mat-select{
      line-height: 24px;
      font-size: 11px;
    }
    input{
      font-size: 11px;
    }
  }
  .new-InputWidth-right{
    left: calc((21% - 0.75px + 1px) * 3) !important;
    width: calc((37% - 0.75px) * 1 + 0px) !important;
    mat-select{
      line-height: 24px;
      font-size: 11px;
    }
    input{
      font-size: 11px;
    }
  }
  .new-Input-small{
    width: calc((15% - 0.75px) * 1 + 0px) !important;
    left: 70px !important;
    mat-select{
      line-height: 24px;
      font-size: 11px;
    }
    input{
      font-size: 11px;
    }
  }
  .label-width{
    min-width: 62px !important;
    max-width: 70px !important;
  }
  .input-width{
    width: -webkit-fill-available !important;
    left: 70px !important;
    mat-select{
      line-height: 24px;
      font-size: 11px;
    }
    input{
      font-size: 11px;
    }
  }
  .label-width-small{
    min-width: 35px !important;
    max-width: 40px !important;
  }
  .input-width-small{
    width: -webkit-fill-available !important;
    left: 40px !important;
    mat-select{
      line-height: 24px;
      font-size: 11px;
    }
    input{
      font-size: 11px;
    }
  }
  .label-width-large{
    min-width: 75px !important;
    max-width: 80px !important;
  }
  .input-width-large{
    width: -webkit-fill-available !important;
    left: 80px !important;
    mat-select{
      line-height: 24px;
      font-size: 11px;
    }
    input{
      font-size: 11px;
    }
  }
  .mat-grid-tile.note-label-width {
    min-width: 35px !important;
    max-width: 40px !important;
    top:-3px !important;
    div {
      top: 5px;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: stretch !important;
      justify-content: left !important;
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }
  .mat-grid-tile.note-input-width {
    width: -webkit-fill-available !important;
    left: 40px !important;
    div {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: stretch !important;
      justify-content: left !important;
      height: 100%;
      padding: 0;
      margin: 0;
      textarea
      {
        width: -webkit-fill-available !important;
        font-size: 11px;
      }
    }
  }
}
// for grid tile

// Spinner loader

.loading {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 10 !important;
}

mat-spinner {
  zoom: 0.6 !important;
}
// Spinner loader

// According to New Changes //

// -----------------dhanashree----------

.label-width-larger-font{
  min-width: 120px !important;
  max-width: 170px !important;
}
.input-width-larger-font{
  width: -webkit-fill-available !important;
  left: 120px !important;
  mat-select{
    line-height: 24px;
    font-size: 11px;
  }
  input{
    font-size: 11px;
  }
}

.mat-grid-tile.comments-label-width {
  min-width: 62px !important;
  max-width: 70px !important;
  div {
    top: 2px !important;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: stretch !important;
    justify-content: left !important;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}
.mat-grid-tile.comments-input-width {
  width: -webkit-fill-available !important;
  left: 70px !important;
  div {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: stretch !important;
    justify-content: left !important;
    height: 100%;
    padding: 0;
    margin: 0;
    textarea
    {
      width: -webkit-fill-available !important;
      font-size: 11px;
    }
  }
}

// -----------------dhanashree----------

.label-width-larger{
  min-width: 90px !important;
  max-width: 95px !important;
}

.input-width-larger{
  width: -webkit-fill-available !important;
  left: 95px !important;
  mat-select{
    line-height: 24px;
    font-size: 11px;
  }
  input{
    font-size: 11px;
  }
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
  width: 100% !important;

}

// for calculation setting tab
.ngx-datatable.material .datatable-header {
  border-bottom: none !important;
}
// for calculation setting tab



// ag grid starts...
.ag-header-cell-label .ag-header-cell-text {
  /*Force the width corresponding at how much width
    we need once the text is laid out vertically*/
font-size: 11px;
font-weight: bold;
}

.ag-header-cell {
  display: -webkit-inline-box;
  margin-top: 4px;
  padding: 0 8px !important; 
}

.ag-header-group-cell-label, .ag-header-cell-label{
  justify-content: center;
}


.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  font-size: 11px;
  font-weight: bold;
  margin: 0 0 0 3px;
  word-wrap: break-word;
  overflow-wrap: break-word !important;
}

.colorRed{
  color: red;
}
.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.colorRed{
  color:red !important;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}
// ag grid end...

.my-dialog-container{
  // padding: 20px;
  max-width: 100% !important;
  width: 580px;
  z-index: 999 !important;
  overflow: unset;
  .mat-dialog-container{
    overflow: unset !important;
    .mat-card{
    overflow: unset;
    }
  }
  .borderdiv{
    margin-top: 15px;
    background-color: transparent !important;
    border-radius: 0;
    bottom: 0;
    border-bottom: 1px solid #e5e5e5;
    font-size: 0;
  }
  .mat-card-header{
    padding: 15px !important;
  }
  .mat-card-title{
    font-size: 24px !important; 
    font-weight: 500 !important;
  }
  .mat-card-content{
    // max-height: 500px;
    // overflow-y: auto;
    padding: 20px;
  }
  .form-group{
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    .form-label{
        display: block;
        font-weight: 700;
        width: 125px;
        min-width: 125px;
        font-size: 14px;
    }
    .form-control{
      display: block;
      width: 100%;
      min-height: 34px !important;
      padding: 6px 12px !important;
      font-size: 14px !important;
      line-height: 1.428571429 !important;
      color: #555 !important;
      // vertical-align: middle;
      background-color: #fff !important;
      border: 1px solid #ccc !important;
      border-radius: 4px;
      .mat-select-value-text{
        font-size: 14px;
        padding-left: 0;
      }
      &:hover{
        color: #333;
        background-color: #ebebeb;
        border-color: #adadad;
      }
      &.custom-dropdown{
        height: auto;
        .dropdown-list{
          left: -12px;
          right: -12px;
          width: auto;
          z-index: 1000 !important;
        }
        .dropdown-btn {
          padding: 0;
          border: 0;
          .dropdown-multiselect__caret{
            top: -8px;
            right: -10px;
            &::before{
              border-width: 5px 5px 0;
              border-color: #757575 transparent;
              top: 63%;
            }
          }
        }
        
      }
    }
    // &.date-form-group{
    //   // .custom-dropdown{
    //   //   // margin-right: 10px;
    //   // }
    // }
  }
  .mat-card-footer{
    padding: 15px 20px 20px;
    .btn-primary{
      color: #fff;
      background-color: #428bca;
      border-color: #357ebd;
      display: inline-block;
      padding: 6px 12px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.428571429;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: none;
      border: none;
    }
    .btn-light{
      color: #fff !important;
    background-color: #428bca;
    border-color: #357ebd;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: none;
      border: none;
    }
  }
  @media (max-width: 576px){
    max-width: 95% !important;
    // overflow-y: auto;
    margin: 30px 0;
    .mat-card-content{
      padding: 15px;
    }
  }
}
.cdk-global-overlay-wrapper{
  @media (max-width:767.98px){
    // overflow-y: auto !important;
    height: auto;
    align-items: unset !important;
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.width-279{
  width: 279px !important;
}
.material-symbols-outlined{
  font-size: 30px !important;
  font-weight: bold !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.lock-color {
  color: #428bca;
}

.opacity_4{
  opacity: 0.4 !important;
}
.user-role-reset-filter
{
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1); 
}
.ag-header-cell-comp-wrapper {
  width: 91%;
  overflow: hidden;
}
.mx-udf-select {
  margin-left: 476px!important;
  margin-right: 10px !important;
  width:130px !important
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .1em 1em 0 !important;
  color: #1a237e !important;
  font-size: 18px !important;
  font-weight: 500;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 0.3em 1.6em .3em !important;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 14px !important;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0,100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 12px !important;
}
.mx-2-select
{
  margin-left: 622px !important;
  margin-right: 6px !important;
  width: 130px !important;
}

  .mx-3
{
  //margin-left: 10px !important;
  margin-right: 6px !important;
  width: 130px !important;
}
.mx-4-select
{
  margin-left: 670px !important;
  margin-right: 6px !important;
  width: 130px !important;
}
.mx-5-select
{
  margin-left: 550px !important;
  margin-right: 6px !important;
  width: 130px !important;
}

input[type="checkbox"] {
  background: #990000;    
}

.chk {
 background-color: #990000;  
  
}

.ag-theme-balham {
  /* disable all borders */
  --ag-borders: none;

  --ag-row-border-color: transparent;
}

.disableInput {
  pointer-events: none;
  opacity: 0.4;
  cursor: not-allowed;
}
.form-label
{
    font-size: 11px;
    font-weight: bold;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
 padding: 0 !important; 
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 !important; 
}

.mat-form-field-infix {
  padding: .5em 0;
  border-top: 0 !important
}

.mat-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 100% !important;
}

.mat-form-field-flex {
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 95% !important;
  }

  .pb-10
  {
  padding-bottom: 10px;
  }

  ::ng-deep .ngx-charts-legend-text-wrapper {
    max-width: unset !important;
  }

  ::ng-deep .ngx-charts-legend-text {
    font-size: 12px;
  }

  .chart-legend .legend-labels {
    line-height: 85%;
    list-style: none;
    text-align: left;
    float: left;
    width: auto !important;
    /* width: 100%; */
    border-radius: 3px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    background: rgba(0,0,0,.05);
}
.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 13px 8px !important;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 0 !important; 
  height: 1px;
}

.disableInput select
{
  background-color:rgba(189, 198, 198, 0.807);
}

.long-text
{
  white-space: normal !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}


 .ActiveMenu
{
background-color: #0075ff;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  margin-top: -3%;
}

